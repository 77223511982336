// Hero
function parallaxHero() {
  var scrollVar = $(window).scrollTop();
  var ticking = false;

  // Hero
  var $depth2 = $('.hero.hero--internal .hero__inner, .hero.hero--home .hero__inner, .hero--restaurant .hero__inner');
  var $bottom = $('.bottom');

  // Everything plz
  var $all = $('.hero.hero--internal .bg-img, .hero.hero--home .bg-img, .hero.hero--restaurant .bg-img, .hero.hero--internal .hero__inner, .hero.hero--home .hero__inner, .hero.hero--restaurant .hero__inner');

  function parallax(){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var $postList = $('.posts.post-list');
    var $depth1 = $('.hero.hero--restaurant .bg-img, .hero.hero--home .bg-img, .hero.hero--internal .bg-img');

    if(windowWidth >= 768){

      // Hero
      var limitedScrollVar = scrollVar * 0.5;
      var opacity = (1 - scrollVar/($(window).height() * 1.4));
      var opacity2 = (1.05 - scrollVar/($(window).height() * 0.9));

      $depth1.css({ 'transform': 'translate3d(0px, ' + (0.7 * limitedScrollVar) + 'px, 0px)' });
      $depth2.css({ 'transform': 'translate3d(0px, ' + (0.3 * limitedScrollVar) + 'px, 0px)', 'opacity': opacity2 });

      if($bottom.length > 0) {
        $bottom.css({ 'opacity': opacity });
      }
    }
    else {
      $all.css({ 'transform': 'initial', 'opacity': 1, 'top': 'auto' });
    }
   }

  function requestTick() {
    if(!ticking) {
      window.requestAnimationFrame(parallax);
    }
    ticking = true;
  }

  $(window).scroll(function(){
    scrollVar = $(window).scrollTop();
    requestTick();
  });
  requestTick();
}

// Init
function parallaxInit() {
  parallaxHero();
}