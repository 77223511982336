//
// Menu
// --------------------------------------------------

function stickyHeaderInit() {
  var header = $(
    '.region--header, .nav__toggle, .nav__phone, .contact-info--mobile'
  );
  var headerHeight = header.outerHeight();
  var lastScrollTop = 0;
  var scrollVar =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;
  var ticking = false;

  function hasScrolled() {
    var body = $('body');
    const $desktopMenuItems = $('#menu-primary-navigation >.menu-item');
    // Gotta scroll more doe
    if (lastScrollTop - scrollVar >= -25 && lastScrollTop - scrollVar < 0) {
      return;
    }

    // Control header floating
    if (scrollVar > headerHeight + 25) {
      $desktopMenuItems.removeClass('hover-active click-active');
      if (body.hasClass('header-float')) {
        // Control showing/hiding
        if (scrollVar > lastScrollTop) {
          header.removeClass('header-down').addClass('header-up');
        } else if (scrollVar < lastScrollTop) {
          if (scrollVar + $(window).height() < $(document).height()) {
            header.removeClass('header-up').addClass('header-down');
          }
        }
      } else {
        body.addClass('header-float');
      }
    } else if (!header.hasClass('header-down')) {
      body.removeClass('header-float');
    } else if (scrollVar <= 5) {
      body.removeClass('header-float');
      header.removeClass('header-down header-up');
    }
    lastScrollTop = scrollVar;
  }

  function stickyHeader() {
    ticking = false;
    scrollVar =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    hasScrolled();
  }

  function requestTick() {
    if (!ticking) {
      window.requestAnimationFrame(stickyHeader);
    }
    ticking = true;
  }

  $(window).scroll(function() {
    requestTick();
  });
  requestTick();
}

// Mobile nav

function mobileNavInit() {
  function hideFooter() {
    $('#mobile-footer, .nav__phone--mobile, .nav--mobile .button').velocity({
      opacity: 0,
    });
  }

  function showFooter() {
    $('#mobile-footer, .nav__phone--mobile, .nav--mobile .button').velocity(
      {
        opacity: 1,
      },
      {
        delay: 700,
      }
    );
  }

  function adjustHeight(e) {
    $('#ml-menu').outerHeight($(e).outerHeight());
  }

  var menuEl = document.getElementById('ml-menu'),
    mlmenu;

  mlmenu = new MLMenu(menuEl, {
    breadcrumbsCtrl: false, // show breadcrumbs
    backCtrl: true, // show back button
    itemsDelayInterval: 90, // delay between each menu item sliding animation
  });

  // // TODO: Do this better
  $('.menu__level[data-menu="main"] .menu__link[data-submenu]').on(
    'click',
    function() {
      hideFooter();
    }
  );

  $('.menu__back').on('click', function() {
    if ($('[data-depth="1"]').hasClass('menu__level--current')) {
      showFooter();
    }
  });

  adjustHeight('.menu__level');
}

// Do stuff

jQuery(document).ready(function($) {
  const $desktopMenuItems = $('#menu-primary-navigation >.menu-item');
  // Desktops
  $desktopMenuItems.hover(
    function() {
      const itemCount = $(this).find('.sub-menu-wrapper > ul > li').length;
      const height = Math.ceil(itemCount/2) * 65;
      $desktopMenuItems.removeClass('hover-active');
      $('#menu-primary-navigation .menu-item.click-active')
        .not(this)
        .find('.sub-menu-wrapper, >a img')
        .css({ visibility: 'hidden', opacity: 0,height:height+'px !important' });
        $(this).addClass('hover-active')
        .find('.sub-menu-wrapper > ul')
        .attr('style','height:'+height+'px !important');
        $('#menu-primary-navigation .menu-item.click-active')
        .not(this)
        .find('>a img')
        .css({ height:$(this).outerHeight(true)+'px' });
    },
    function() {
      $desktopMenuItems
        .find('.sub-menu-wrapper, >a img')
        .css({ visibility: '', opacity: '' });
      $(this).removeClass('hover-active');
    }
  );
});
