// The flow can shift in the dom if the heights are not calculated before hand.
// This can effect smooth scrolling and other position dependant items
// This relies on the ratio of the image
function lazysizes_placeholder() {
  $(".lazy-img-parent").each(function(e){
    var $that    = $(this);
    var $img_obj = $that.find('img');
    var $ratio   = $img_obj.data('ratio');  
    
    //get the width values from css as it's more consistant to work with.
    var $parent_width = parseInt($that.parent().css('width'));
    var $window_width = parseInt($('.l-page').css('width'));

    //get the current height of the image based on the ratio
    $img_height   = $parent_width * $ratio + "px";

    //Convert the pix unit to the responsive VW un case the window is resized before load.
    //$rs_height    = ($img_height / $window_width) * 100;
    //$rs_height_vw = $rs_height + "vw";          

    //set that bad boy
    $that.parent().css('min-height', $img_height);           

    $that.parent().addClass('has-lazy-min-height');
  });

  //TODO Make this more resize friendly. 
  //For now ditch the min height on resize
  var removeHeightPlaceholder = debounce(function() {
    $('.has-lazy-min-height').css('min-height', '0');
    $('.has-lazy-min-height').removeClass('has-lazy-min-height');
  }, 250);

  window.addEventListener('resize', removeHeightPlaceholder);
}