//Desktop Nav
function desktopNavInit() {
  var $navParentLinks = $('#menu-primary-navigation >.menu-item');
  var $menuMsg = $('.menu-message');
  var userAgent, ieReg, ie;
  userAgent = window.navigator.userAgent;
  ieReg = /msie|Trident.*rv[ :]*11\./gi;
  ie = ieReg.test(userAgent);

  function removeOuterMenuMsg() {
    $('.nav__main__inner > .menu-message').remove();
  }

  function setParentMenuImageHeight() {
    // set submenu image height
    $navParentLinks.each(function() {
      const height = $(this)
        .find('> .sub-menu-wrapper')
        .outerHeight();
      const $img = $(this).find('> a img');
      $img.css('height', height);

      // object-fit fix for IE
      if (ie) {
        var imgUrl = $img.prop('src');
        if (imgUrl) {
          $('<div class="img-container"></div>')
            .appendTo($(this).find('>a'))
            .css({ backgroundImage: 'url(' + imgUrl + ')', height: height })
            .addClass('custom-object-fit');

          $img.hide();
        }
      }
    });
  }

  function subMenuInit() {
    $navParentLinks.each(function() {
      const $submenuItems = $(this).find('> .sub-menu li');
      if ($(this).find('> .sub-menu').length) {
        $menuMsg
          .show()
          .clone()
          .appendTo($(this));
      }

      // put a wrapper around submenu
      $('ul.sub-menu, .menu-message, .texture', this).wrapAll(
        "<div class='sub-menu-wrapper'></div>"
      );

      if (!$('> a img', this).length) {
        $('>.sub-menu-wrapper', this).css({
          width: '82vw',
          'max-width': ' 1240px',
        });
      }
    });
    $('body').on('scroll', function() {
      $navParentLinks.removeClass('hover-active');
    });
  }

  subMenuInit();
  setParentMenuImageHeight();

  removeOuterMenuMsg();

  $(window).resize(setParentMenuImageHeight);
}
