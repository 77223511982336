(function($) {
  'use strict';
  $(function(){
    $('.accordion__heading').toggleClass('is-inactive');
      // The Accordion Effect
      $('.accordion__heading').click(function (e) {
        e.preventDefault();
        if($(this).is('.is-inactive')) {
          $('.accordion__heading.is-active').toggleClass('is-active').toggleClass('is-inactive').next().slideToggle().toggleClass('open-content');
          $(this).toggleClass('is-active').toggleClass('is-inactive');
          $(this).next().slideToggle().toggleClass('open-content');
        }
        else {
          $(this).toggleClass('is-active').toggleClass('is-inactive');
          $(this).next().slideToggle().toggleClass('open-content');
        }
      });
  });
})(jQuery);
