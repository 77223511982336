/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var BrowserDetect = {
  init: function() {
    this.browser = this.searchString(this.dataBrowser) || 'Other';
    this.version =
      this.searchVersion(navigator.userAgent) ||
      this.searchVersion(navigator.appVersion) ||
      'Unknown';
  },
  searchString: function(data) {
    for (var i = 0; i < data.length; i++) {
      var dataString = data[i].string;
      this.versionSearchString = data[i].subString;

      if (dataString.indexOf(data[i].subString) !== -1) {
        return data[i].identity;
      }
    }
  },
  searchVersion: function(dataString) {
    var index = dataString.indexOf(this.versionSearchString);
    if (index === -1) {
      return;
    }

    var rv = dataString.indexOf('rv:');
    if (this.versionSearchString === 'Trident' && rv !== -1) {
      return parseFloat(dataString.substring(rv + 3));
    } else {
      return parseFloat(
        dataString.substring(index + this.versionSearchString.length + 1)
      );
    }
  },

  dataBrowser: [
    { string: navigator.userAgent, subString: 'Chrome', identity: 'Chrome' },
    { string: navigator.userAgent, subString: 'MSIE', identity: 'Explorer' },
    { string: navigator.userAgent, subString: 'Trident', identity: 'Explorer' },
    { string: navigator.userAgent, subString: 'Firefox', identity: 'Firefox' },
    { string: navigator.userAgent, subString: 'Safari', identity: 'Safari' },
    { string: navigator.userAgent, subString: 'Opera', identity: 'Opera' },
  ],
};

BrowserDetect.init();

Modernizr.addTest(
  'svgasimg',
  document.implementation.hasFeature(
    'http://www.w3.org/TR/SVG11/feature#Image',
    '1.1'
  )
);

// Do things

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        if (
          BrowserDetect.browser === 'Explorer' &&
          BrowserDetect.version === 11
        ) {
          $('html').addClass('is-oldIE');
        }

        // JavaScript to be fired on all pages
        svg4everybody();

        //Add class for local font familes
        var wayfaring = new FontFaceObserver('Wayfaring Font Two');
        var trade_supply = new FontFaceObserver('Trade Supply Textured');

        Promise.all([wayfaring.load(), trade_supply.load()]).then(
          function() {
            $('body').addClass('fonts-loaded');
          },
          function() {
            $('body').addClass('fonts-notloaded');
          }
        );

        if (!Modernizr.touchevents) {
          $("a[href*='tel:']").on('click', function() {
            return false;
          });
        }
        $("[data-block], a[href^='#']").on('click', function() {
          var $el = $($(this).data('block') || $(this).attr('href'));
          $doc_offset = $(document).scrollTop();
          if ($el.length > 0) {
            //Going Up
            if ($doc_offset > $el.offset().top) {
              $offset =
                $el.offset().top - parseInt($('.region--header').css('height'));

              //Going Down
            } else {
              $offset = $el.offset().top;
            }

            $('html').velocity('scroll', {
              offset: $offset,
              mobileHA: false,
              duration: 1250,
            });
            return false;
          }
        });

        // Hash scroll
                // skip ga hash links as it has "=" that breaks
        // the site
        if (!String.prototype.includes) {
          String.prototype.includes = function(search, start) {
            'use strict';
            if (typeof start !== 'number') {
              start = 0;
            }
        
            if (start + search.length > this.length) {
              return false;
            } else {
              return this.indexOf(search, start) !== -1;
            }
          };
        }
        
        if(window.location.hash && !window.location.hash.includes('=')) {
          if ($(window.location.hash).length > 0) {
            $(window.location.hash).velocity('scroll');
          }
        }

        $('a.video').magnificPopup({
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 300,
          callbacks: {
            open: function() {
              var pInstance = $('.hero--home').data('vide');
              if (pInstance) {
                pInstance = pInstance.getVideoObject();
                pInstance.pause();
              }
            },
            close: function() {
              var pInstance = $('.hero--home').data('vide');
              if (pInstance) {
                pInstance = pInstance.getVideoObject();
                pInstance.play();
              }
            },
          },
          iframe: {
            markup:
              '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
              '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

            patterns: {
              youtube: {
                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                // Or null - full URL will be returned
                // Or a function that should return %id%, for example:
                // id: function(url) { return 'parsed id'; }

                src: '//www.youtube.com/embed/%id%?autoplay=1', // URL that will be set as a source for iframe.
              },
              vimeo: {
                index: 'vimeo.com/',
                id: '/',
                src: '//player.vimeo.com/video/%id%?autoplay=1',
              },
              gmaps: {
                index: '//maps.google.',
                src: '%id%&output=embed',
              },
            },
          },
        });

        //Gallery init
        $('.content-block').each(function() {
          $(this).magnificPopup({
            delegate: '.js-gallery__item',
            type: 'image',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            gallery: {
              enabled: true,
            },
            image: {
              markup:
                '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                '<div class="mfp-bottom-bar">' +
                '<div class="mfp-title"></div>' +
                '<div class="mfp-counter"></div>' +
                '</div>' +
                '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

              cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

              //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
              // Or the function that should return the title. For example:
              titleSrc: function(item) {
                //console.log($(item.el).find('img').attr('title'));
                //return $(item.el).find('img').attr('title');
                return '';
              },

              verticalFit: true, // Fits image in area vertically

              tError: '<a href="%url%">The image</a> could not be loaded.', // Error message
            },
            callbacks: {
              open: function() {
                $.magnificPopup.instance.next = function() {
                  var self = this;
                  self.wrap.removeClass('mfp-image-loaded');
                  self.wrap.addClass('next');
                  setTimeout(function() {
                    $.magnificPopup.proto.next.call(self);
                  }, 400);
                };
                $.magnificPopup.instance.prev = function() {
                  var self = this;
                  self.wrap.removeClass('mfp-image-loaded');
                  self.wrap.addClass('prev');
                  setTimeout(function() {
                    $.magnificPopup.proto.prev.call(self);
                  }, 400);
                };
              },
              imageLoadComplete: function() {
                var self = this;
                self.wrap.addClass('mfp-image-loading');
                setTimeout(function() {
                  self.wrap.removeClass('mfp-image-loading prev next');
                  self.wrap.addClass('mfp-image-loaded');
                }, 10);
              },
            },
          });
        });

        //fire the map if it exists
        var gmapScript =
          'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit&key=' +
          GOOGLE_API_KEY;
        if ($('#map-canvas').length !== 0) {
          $.getScript(gmapScript)
            .done(function(script, textStatus) {
              //console.log( script );
            })
            .fail(function(jqxhr, settings, exception) {
              //$( "div.log" ).text( "Triggered ajaxError handler." );
            });
        }
      },
      finalize: function() {
        objectFitImages();

        stickyHeaderInit();

        parallaxHero();

        Pace.once('done', function() {
          lazysizes_placeholder();
        });

        $('nav.greedy').makeGreedy();

        // We probably don't need this right away
        $('.slider').each(function() {
          var visibleSlides = $(this).data('visible-slides');
          var pagination = $(this).data('pagination');
          var navigation = $(this).data('navigation');
          var loop = $(this).data('loop');
          var autoplay = $(this).data('autoplay');
          var params = {
            direction: 'horizontal',
            loop: loop,
            slidesPerView: visibleSlides,
            speed: 600,
            paginationClickable: true,
            spaceBetween: 0,
          };

          if (pagination) {
            params.pagination = '.swiper-pagination';
          }

          if (autoplay) {
            params.autoplay = autoplay;
          }

          if (navigation) {
            params.nextButton = '.swiper-next';
            params.prevButton = '.swiper-prev';
          }

          if (visibleSlides > 1) {
            params.breakpoints = {
              320: {
                slidesPerView: 1,
              },
            };

            if (visibleSlides > 2) {
              params.breakpoints['640'] = {
                slidesPerView: 2,
              };
            }

            if (visibleSlides > 3) {
              params.breakpoints['768'] = {
                slidesPerView: 3,
              };
            }
          }

          var swiper = new Swiper($(this).find('.swiper-container'), params);
        });

        //Slider on single adventure page
        if (document.querySelectorAll('.adventure-single').length > 0) {
          var $this = $('.adventure-single');
          var adventure_swiper = new Swiper($this.find('.swiper-container'), {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            speed: 600,
            paginationClickable: true,
            spaceBetween: 0,
            navigation: {
              prevEl: $this.find('.swiper-prev'),
              nextEl: $this.find('.swiper-next'),
            },
          });
        }

        // Team Loader
        if (document.querySelectorAll("[data-loader='team']").length > 0) {
          var teamLoader = new Loader('.loader--team', {
            triggerData: 'team',
            updateContent: true,
          });
        }

        // Team Loader
        if (document.querySelectorAll("[data-loader='book-now']").length > 0) {
          var bookNowLoader = new Loader('.loader--book-now', {
            triggerData: 'book-now',
          });
        }

        // Fly out
        if (document.querySelectorAll("[data-loader='flyout']").length > 0) {
          var flyoutLoader = new Loader('.loader--flyout', {
            triggerData: 'flyout',
            updateContent: true,
          });
        }

        if ($('.loader--popup').length) {
          var popupLoader = new Loader('.loader--popup');
          setTimeout(function() {
            popupLoader.toggle();
          }, popupLoader.loader.data('delay'));
        }

        //Disable trigger to enable menu depending on screen size.
        var menuLoader = new Loader('.nav--mobile--primary', {
          simpleClose: true,
          //trigger: '.nav__toggle__trigger'
        });

        $('.nav__toggle__trigger').on('click', function() {
          var $window_width = parseInt($('.l-page').css('width')),
            $this = $(this);

          if ($window_width <= 1150) {
            $this.toggleClass('is-active is-active--mobile');
            $this.parent().toggleClass('menu-active');
            menuLoader.toggle();
          }
        });

        $('.loader--book-now .loader__close').on('click', function(e) {
          //menuLoader.close();
        });

        //Desktop
        desktopNavInit();

        // Mobiles
        mobileNavInit();

        //Book Now Date Picker
        // $.datepicker.setDefaults(
        //   $.extend(
        //     $.datepicker.regional['']
        //   )
        // );
        // var intDaysInAdv = -1;
        // if (intDaysInAdv < 1) {
        //   intDaysInAdv = 0;
        // }
        // var strDefaultDate = onresGetDateInAdv(intDaysInAdv);

        // $("#StartDate").datepicker({
        //   numberOfMonths: 1,
        //   dateFormat: "d MM yy",
        //   defaultDate: strDefaultDate,
        //   minDate: strDefaultDate,
        // });

        // document.getElementById("StartDate").value = strDefaultDate;

        //Scroll Magic
        var controller = new ScrollMagic.Controller();

        $('.scroll-magic').each(function() {
          var that = this;

          var scene = new ScrollMagic.Scene({
            triggerElement: that,
            triggerHook: 0.9,
          })
            .setClassToggle(that, 'scroll-triggered')
            .addTo(controller);
        });

        //Bucket list button
        bucketListButton();
      }, //init
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
        var hero_tl = new TimelineLite({ paused: true });
        hero_tl
          .set($('.hero__heading .fancy-font'), {
            autoAlpha: 0,
          })
          .fromTo(
            $('.hero__heading'),
            0.5,
            {
              autoAlpha: 0,
              y: '20px',
            },
            {
              autoAlpha: 0.85,
              y: '0px',
              ease: Sine.easeOut,
            }
          )
          .fromTo(
            $('.hero__heading .fancy-font'),
            0.5,
            {
              autoAlpha: 0,
              y: '20px',
            },
            {
              autoAlpha: 0.9,
              y: '0px',
              ease: Sine.easeOut,
            },
            '-=0.25'
          )
          .fromTo(
            $('.hero__icon'),
            0.5,
            {
              autoAlpha: 0,
              y: '20px',
            },
            {
              autoAlpha: 0.9,
              y: '0px',
              ease: Sine.easeOut,
            },
            '-=0.25'
          )
          .fromTo(
            $('.hero__tag-line'),
            0.5,
            {
              autoAlpha: 0,
              y: '20px',
            },
            {
              autoAlpha: 0.9,
              y: '0px',
              ease: Sine.easeOut,
            },
            '-=0.25'
          );

        Pace.once('done', function() {
          hero_tl.play();
        });

        //Scrolling Way points
        var content_blocks = document.getElementsByClassName('scroll-to');
        for (var i = 0; i < content_blocks.length; i++) {
          new Waypoint({
            element: content_blocks[i],
            offset: 50,
            handler: function(direction) {
              var that = $(this.element);
              section_id = that.attr('id');

              if (direction === 'down') {
                $('.home__page-nav__list-link.section-triggered').removeClass(
                  'section-triggered'
                );
                $('.scroll-to--' + section_id).addClass('section-triggered');
              }
            },
          });
          new Waypoint({
            element: content_blocks[i],
            offset: -50,
            handler: function(direction) {
              var that = $(this.element);
              section_id = that.attr('id');

              if (direction === 'up') {
                $('.home__page-nav__list-link.section-triggered').removeClass(
                  'section-triggered'
                );
                $('.scroll-to--' + section_id).addClass('section-triggered');
              }
            },
          });
        }

        new Waypoint({
          element: $('#hero'),
          offset: '-80%',
          handler: function(direction) {
            $('.home__page-nav__list-link.section-triggered').removeClass(
              'section-triggered'
            );
          },
        });

        //Home Page Navigation
        var home_page_nav = $('.home__page-nav'),
          home_page_nav_list = home_page_nav.find('.home__page-nav__list');
        home_page_nav_trigger = home_page_nav.find('.home__page-nav__trigger');

        new Waypoint({
          element: $('#top'),
          offset: -5,
          handler: function(direction) {
            if (direction === 'up') {
              home_page_nav.removeClass('hidden-menu');
            } else {
              home_page_nav.addClass('hidden-menu');
            }
          },
        });

        home_page_nav_trigger.on('click', function() {
          home_page_nav.toggleClass('show');
        });

        $(document).keyup(function(e) {
          if (e.keyCode === 27 && home_page_nav.hasClass('show')) {
            home_page_nav.removeClass('show');
          }
        });

        $(document).click(function(event) {
          if (!$(event.target).closest('.home__page-nav').length) {
            if (home_page_nav.hasClass('show')) {
              home_page_nav.removeClass('show');
            }
          }
        });

        //Set 100vh static on touch devices No jittering from Browser navigation
        function set_home_hero_height() {
          $('.hero').css('height', document.documentElement.clientHeight);
        }

        if (Modernizr.touchevents) {
          set_home_hero_height();
          window.addEventListener('orientationchange', set_home_hero_height);
        }
      },
      finalize: function() {},
    },
    // About us page, note the change from about-us to about_us.
    not_front: {
      init: function() {},
    },
    our_rooms: {
      init: function() {
        // Init all gallerys for "our rooms"
        if (document.querySelectorAll('.room__slider').length > 0) {
          var $room_swipers = [];
          var $room_sliders = $('.room__slider');

          for (var i = $room_sliders.length - 1; i >= 0; i--) {
            $container = $($room_sliders[i]).find('.swiper-container');
            $swiper_prev = $($room_sliders[i]).find('.swiper-prev');
            $swiper_next = $($room_sliders[i]).find('.swiper-next');
            var params = {
              navigation: {
                prevEl: $swiper_prev,
                nextEl: $swiper_next,
              },
              direction: 'horizontal',
              loop: true,
              effect: 'slide',
              speed: 1200,
              slidesPerView: 1,
              on: {
                init: function() {
                  //Fire popup on cloned slides
                  $('.swiper-slide-duplicate .js-room_gallery__item').on(
                    'click',
                    function(e) {
                      e.preventDefault();
                      $slide = $(this).closest('.swiper-slide');

                      if ($slide.is(':first-child')) {
                        $slide
                          .siblings('.swiper-slide-duplicate:last-child')
                          .prev()
                          .find('.js-room_gallery__item')
                          .trigger('click');
                      }

                      if ($slide.is(':last-child')) {
                        $slide
                          .siblings('.swiper-slide-duplicate:first-child')
                          .next()
                          .find('.js-room_gallery__item')
                          .trigger('click');
                      }
                    }
                  );
                },
              },
            };
            $room_swipers[i] = new Swiper($container, params);
          }
        }

        //Gallery
        $('.room__images').each(function() {
          $(this).magnificPopup({
            type: 'image',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            delegate:
              '.swiper-slide:not(.swiper-slide-duplicate) .js-room_gallery__item',
            gallery: {
              enabled: true,
            },
            image: {
              markup:
                '<div class="mfp-figure">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-img"></div>' +
                '<div class="mfp-bottom-bar">' +
                '<div class="mfp-title"></div>' +
                '<div class="mfp-counter"></div>' +
                '</div>' +
                '</div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

              cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

              //titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
              // Or the function that should return the title. For example:
              titleSrc: function(item) {
                //console.log($(item.el).find('img').attr('title'));
                //return $(item.el).find('img').attr('title');
                return '';
              },

              verticalFit: true, // Fits image in area vertically

              tError: '<a href="%url%">The image</a> could not be loaded.', // Error message
            },
            callbacks: {
              open: function() {
                $.magnificPopup.instance.next = function() {
                  var self = this;
                  self.wrap.removeClass('mfp-image-loaded');
                  self.wrap.addClass('next');
                  setTimeout(function() {
                    $.magnificPopup.proto.next.call(self);
                  }, 400);
                };
                $.magnificPopup.instance.prev = function() {
                  var self = this;
                  self.wrap.removeClass('mfp-image-loaded');
                  self.wrap.addClass('prev');
                  setTimeout(function() {
                    $.magnificPopup.proto.prev.call(self);
                  }, 400);
                };
              },
              imageLoadComplete: function() {
                var self = this;
                self.wrap.addClass('mfp-image-loading');
                setTimeout(function() {
                  self.wrap.removeClass('mfp-image-loading prev next');
                  self.wrap.addClass('mfp-image-loaded');
                }, 10);
              },
            },
          });
        });
      },
      finalize: function() {},
    },
    contact: {
      init: function() {},
      finalize: function() {},
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        setTimeout(function() {
          UTIL.fire(classnm, 'finalize');
        }, 200);
      });
      // Fire common finalize JS
      setTimeout(function() {
        UTIL.fire('common', 'finalize');
        console.info('main.js file has fired all commands.');
      }, 200);
    },
  };

  //fool the shit out of pagespeed insights
  function loadTheCSS() {
    var stylesheet = loadCSS(cssFile, document.getElementById('css-ref'));
    onloadCSS(stylesheet, function() {
      console.info('Stylesheet has loaded.');
      $(document).ready(UTIL.loadEvents);
    });
  }
  var botPattern = '(Insights)';
  var re = new RegExp(botPattern, 'i');
  var userAgent = navigator.userAgent;
  if (re.test(userAgent)) {
    window.setTimeout(loadTheCSS, 5000);
  } else {
    loadTheCSS();
  }
})(jQuery); // Fully reference jQuery after this point.
