$(function(){
  var setTimer = setInterval(function(){
      element = $('[data-countdown-timer]');
      date_future = new Date(element.data('countdown-timer'));
      date_now = new Date();

      seconds = Math.floor((date_future - (date_now))/1000);
      minutes = Math.floor(seconds/60);
      hours = Math.floor(minutes/60);
      days = Math.floor(hours/24);

      hours = hours-(days*24);
      minutes = minutes-(days*24*60)-(hours*60);
      seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      if (date_future > date_now ) {
        element.text(days + ' Days ' + hours + ' Hours ' + minutes + ' Minutes ' + seconds + ' Seconds');
      } else {
        element.text('0 Days');
      }
  },1000);
});