(function($) {
  'use strict';
  $(function() {
    const $tabDesktopHeading = $('.tab__headings--desktop .tab__heading');
    const $tabMobileHeading = $('.tab__headings--mobile');

    $('.tab__heading').toggleClass('is-inactive');

    // Handle mobile view tab heading clicks
    $tabMobileHeading.on('click', function(e) {
      e.preventDefault();
      const $this = $(this);
      // data attribute
      const tabName = $this.data('tab-name');

      // expanding contents
      if ($this.hasClass('is-inactive')) {
        // set all other heading to inactive state
        $tabMobileHeading
          .not(this)
          .removeClass('is-active')
          .addClass('is-inactive');

        // set itself (heading) to active
        $this.removeClass('is-inactive').addClass('is-active');
        // also need to set the parent so that it keeps the current
        // active state when resizing to desktop
        $this
          .parent()
          .removeClass('is-inactive')
          .addClass('is-active');

        // sync up with desktop tab headings, which is a separate group
        // of DOM elements. Again it's prep for keeping state during
        // window resizing
        $tabDesktopHeading.each(function() {
          if ($(this).data('tab-name') === tabName) {
            $tabDesktopHeading
              .not(this)
              .removeClass('is-active')
              .addClass('is-inactive');
            $(this)
              .addClass('is-active')
              .removeClass('is-inactive');
            return;
          }
        });

        // Find the tab__content within current tab
        $this
          .next()
          .slideToggle()
          .addClass('open-content is-active');
      } else {
        // if the tab is already active
        // we need to close it

        // again, we need to set the parents state
        $this
          .parent()
          .removeClass('is-active')
          .addClass('is-inactive');
        $this.removeClass('is-active').addClass('is-inactive');

        // Sync up with desktop headings just like above
        $tabDesktopHeading.each(function() {
          if ($(this).data('tab-name') === tabName) {
            $tabDesktopHeading.addClass('is-inactive');
            $(this).removeClass('is-active');
            return;
          }
        });
        $this
          .next()
          .slideToggle()
          .removeClass('open-content is-active');
      }
    });

    // handle desktop heading clicks
    $tabDesktopHeading.on('click', function() {
      const $this = $(this);

      const tabName = $this.data('tab-name');

      // desktop tab active state

      // set all other tabs to inactive
      $tabDesktopHeading
        .not(this)
        .addClass('is-inactive')
        .removeClass('is-active');
      // set itself to active
      $this.addClass('is-active').removeClass('is-inactive');

      // Sync up states with mobile tab headings
      // Same reasoning found above in the mobile implementation
      $tabMobileHeading.each(function() {
        if ($(this).data('tab-name') === tabName) {
          $(this)
            .addClass('is-active')
            .removeClass('is-inactive');
          $tabMobileHeading
            .not(this)
            .addClass('is-inactive')
            .removeClass('is-active');
          return;
        }
      });

      // show tab content based on what heading is clicked
      // mobile version we are using jquery slideToggle, but we are still
      // setting it up in prep for desktop version
      // Here we need to set ".tab", ".tab__content"
      $('.tab').each(function() {
        const $this = $(this);
        if ($this.data('tab-name') === tabName) {
          $('.tab')
            .not(this)
            .addClass('is-inactive')
            .removeClass('is-active');
          $this.removeClass('is-inactive').addClass('is-active');

          $('.tab__content')
            .removeClass('is-active')
            .addClass('is-inactive');
          $this
            .find('.tab__content')
            .removeClass('is-inactive')
            .addClass('is-active');
        }
      });
    });

    // on load when it's a desktop view (lt), first tab to be active by default
    if ($(window).width() >= 960) {
      $('.tab')
        .first()
        .addClass('is-active')
        .find('.tab__content,  .tab__headings--mobile')
        .removeClass('is-inactive')
        .addClass('is-active');

      $('.tab:not(:first)').addClass('is-inactive');
      $('.tab__headings--desktop .tab__heading')
        .first()
        .removeClass('is-inactive')
        .addClass('is-active');
    }

    $(window).resize(function() {
      if ($(window).width() >= 960) {
        // when resizing to desktop from mobile view, if the mobile view
        // has all tabs collapsed, we need to set the default desktop display
        // to be the first tab
        if (
          $tabDesktopHeading.length ===
          $('.tab__headings--desktop').find('> .is-inactive').length
        ) {
          $tabDesktopHeading.first().trigger('click');
        }

        // this is to reset the jquery slideToggle inline style
        $('.tab__content').css('display', '');
      }
    });
  });
})(jQuery);
