/* jshint ignore:start */
jQuery.fn.activityWidgetButton = function (options) {
  this.off('click'); return this.click(function () {
    var merchant, activity, event, config, recursiveDecoded, h, w, iframe, backdrop; merchant = jQuery(this).attr("data-merchant"); activity = jQuery(this).attr("data-activity"); event = jQuery(this).attr("data-event"); config = { merchant: options.merchant, activity: activity, event: event }; if (merchant !== 'undefined' && merchant != null) { config.merchant = merchant; }
    if (config.activity == null || config.activity === 'undefined') { delete config.activity; }
    if (config.event == null || config.event === 'undefined') { delete config.event; }
    recursiveDecoded = decodeURIComponent(jQuery.param(config)); if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())) { h = jQuery(window).height(); w = jQuery(window).width(); return window.open("https://m-widget.adventurebucketlist.com/?" + recursiveDecoded, "Widget", "height=" + h + ", width=" + w + ", top=0, left=0, scrollbars=0").focus(); }
    iframe = jQuery("<iframe frameborder='0'width='1000'height='500'src='https://widget.adventurebucketlist.com/iframe.html?" + recursiveDecoded + "'></iframe>"); window.addEventListener('message', function (event) { switch (event.data) { case 'abl_closable': return event.source.postMessage("abl_closable", event.origin); case 'abl_closed': iframe.remove(); return backdrop.remove(); } }, false); backdrop = jQuery('<div></div>').css('position', 'fixed').css('top', 0).css('left', 0).css('width', '100%').css('height', '100vh').css('background-color', "rgba(0,0,0,0.3)").css('z-index', 999999).css('text-align', 'center').appendTo(jQuery('body')); return iframe.css('display', 'inline-block').css('overview', 'hidden').css("box-shadow", "0px 0px 10px black").css('margin-top', '20px').appendTo(backdrop);
  });
};

/* jshint ignore:end */

function bucketListButton() {
  $(document).ready(function () {
    $("a.abl-activity-button").activityWidgetButton(
      { merchant: "D8HejjZVhG8b2N7evvGEjCJuGdRcQ1fqCbgshfRVKOT2YE6MBv4eiHlMWXQ19va5" });
  });
}
