/**
* Global loader function
*/

var Loader = function(loader, params) {
  //set some variables
  this.overlay       = $(".overlay-show");
  this.showClass     = 'show';
  this.html          = $("html");
  this.loader        = false;
  this.state         = 'closed';
  this.errMessage    = "You have to init the loader first! [ Loader.init() ]";
  this.updateContent = false;
  this.trigger       = false;
  this.simpleClose   = false;

  if(loader) {
    if(window.Loaders === undefined) {
      window.Loaders = [this.init(loader, params)];
    }
    else {
      window.Loaders.push(this.init(loader, params));
    }

    // Attach loader to element
    $(loader).data('loader', this);
  }
};

Loader.prototype = {

  init: function(e, params) {
    var that = this;

    this.parseParams(params);
    this.loader = this._loaderClass(e);

    this.loader.find('.loader__close').on('click',function() {
      that.close();
    });

    this.overlay.on('click',function() {
      that.close();
    });

    $(document).keyup(function(e) {
      if (e.keyCode === 27 && that.is_open()) {
        that.close();
      }
    });

    // $(window).on("orientationchange resize", function() {
    //   that.close();
    // });

    return this;
  },

  parseParams: function(params) {
    if(!params) {
      return;
    }

    var trigger;

    if(params.trigger) {
      this.triggerInit(params.trigger);
    }

    if(params.triggerData) {
      trigger = '[data-loader=' + params.triggerData +']';
      this.triggerInit(trigger);
    }

    if(params.triggerHash) {
      trigger = 'a[href*="#' + params.triggerHash +'"]';
      this.triggerInit(trigger);
    }

    if(params.updateContent) {
      this.updateContent = params.updateContent;
    }

    if(params.simpleClose) {
      this.simpleClose = params.simpleClose;
    }

  },


  triggerInit: function(selector) {
    var that = this;
    var triggers = $(selector);

    this.trigger = triggers;

    triggers.each(function() {
      var trigger = $(this);
      trigger.on('click', function() {
        if(that.updateContent) {
          var contentElement = trigger.data('content');
          var contentDataElement = trigger.find('[data-content]');

          if(contentElement && $(contentElement).length > 0) {
            that.replaceContent($(contentElement).html());
          }
          else if(contentDataElement) {
            that.replaceContent(contentDataElement.html());
          }
        }
        that.toggle();
      });

    });
  },

  openAnimationTween: function() {
    $window_width = $('.l-page').css('width');
    $loader  = $(this.loader);
    $images  = $loader.find('.flyout__images');
    $content = $loader.find('.flyout__content');
    $close   = $loader.find('.icon--close');

    if(this.simpleClose){
      TweenLite.fromTo($loader, 0.5, {
        x: '101%',
      },{
        x: '0%',
        ease: Sine.easeOut,
      });
    } else {
      TweenLite.fromTo($loader, 0, {
        x: '101%'
      },{
        x: '0%',
        onComplete: function(){
          TweenLite.fromTo($content, 0.75, {
            x: '101%'
          },{
            x: '0%',
            ease: Sine.easeOut,
          });
          TweenLite.fromTo($images , 0.75, {
            x: $window_width
          },{
            x: 0,
            ease: Sine.easeOut
          });
          TweenLite.fromTo($close , 0.25, {
            autoAlpha: 0
          },{
            autoAlpha: 1,
          });
        }
      });
    }
  },

  closeAnimationTween: function() {
    $window_width = $('.l-page').css('width');
    $loader  = $(this.loader);
    $images  = $loader.find('.flyout__images');
    $content = $loader.find('.flyout__content');
    $close   = $loader.find('.icon--close');

    if(this.simpleClose){
      TweenLite.fromTo($loader, 0.5, {
        x: '0%',
      },{
        x: '101%',
        ease: Sine.easeOut,
      });

    }else{
      TweenLite.fromTo($close , 0.25, {
        autoAlpha: 1
      },{
        autoAlpha: 0,
      });

      TweenLite.fromTo($content, 0.75, {
        x: '0%',
      },{
        x: '101%',
        ease: Sine.easeOut,
      });

      TweenLite.fromTo($images , 0.75, {
        x: 0,
      },{
        x: $window_width,
        ease: Sine.easeOut,
        onComplete: function(){
          TweenLite.set($loader, {
            x: '101%'
          });
        }
      });
    }
  },

  open: function() {
    //figure out if a click event was sent to it with a data attribute
    if(typeof(this.loader) === 'object'){
      this.loader.scrollTop(0);
      this.loader.addClass(this.showClass);
      this.showOverlay();
      this.state = 'open';

      if(this.simpleClose){
        this.toggleHamburger();
      }
      this.initSlider();
      this.openAnimationTween();

      bucketListButton();


      if(this.loader.find('.no-images').length) {
        $(this.loader).addClass(' has-no-images');
      }else {
        $(this.loader).removeClass(' has-no-images');
      }

    } else{
      console.info(this.errMessage);
    }
    return this.loader;
  },

  close: function() {
    if(typeof(this.loader) === 'object'){
      this.closeAnimationTween();
      this.loader.removeClass(this.showClass);
      this.closeOverlay();
      this.state = 'closed';
      if(this.simpleClose){
        this.toggleHamburger();
      }
    } else{
      console.info(this.errMessage);
    }
    return this.loader;
  },

  toggle: function() {
    if(this.is_open()) {
      this.close();
    }
    else {
      this.open();
    }
  },

  replaceContent: function(content) {
    if(typeof(this.loader) === 'object'){
      this.loader.find('[data-content-replace]').html(content);
    }else{
      console.info(this.errMessage);
    }
    return this.loader;
  },

  showOverlay: function() {
    overlay = this.overlay;
    overlay.addClass(this.showClass);
    this.html.addClass('lock overlay-visible');
  },

  initSlider: function() {
    var params = {
      navigation: {
        prevEl: ".swiper-prev",
        nextEl: ".swiper-next"
      },
      direction: 'horizontal',
      loop: true,
      effect: 'slide',
      speed: 1200,
      slidesPerView: 1,
      on: {
        init: function(){
          // $window_width = $('.l-page').css('width');
          // TweenLite.fromTo($('.flyout__images'),
          //   0.75, //speed
          //   {
          //     x: $window_width
          //   },
          //   {
          //     x: 0
          //   }
          // );
        }
      }
    };
    this.flyout_swiper = new Swiper (this.loader.find('.swiper-container'), params);
  },

  destroySlider: function(){
    if(this.flyout_swiper){
      this.flyout_swiper.destroy();
    }
  },

  toggleHamburger: function() {
    if(this.is_open()) {
      this.loader.find('.hamburger').addClass('is-active');

      if(this.trigger) {
        this.trigger.addClass('is-active');
      }
    }
    else {

      this.loader.find('.hamburger').removeClass('is-active');
      $('.nav__toggle__trigger').removeClass('is-active--mobile');
      $('.nav__toggle').removeClass('menu-active');

      if(this.trigger) {
        this.trigger.removeClass('is-active');
      }
    }
  },

  closeOverlay: function() {
    overlay = this.overlay;
    if($(".loader").hasClass(this.showClass) === false){
      overlay.removeClass(this.showClass);
      this.html.removeClass('lock overlay-visible');
    }
  },

  is_open: function() {
    return (this.state === 'open');
  },

  is_closed: function() {
    return (this.state === 'closed');
  },

  _loaderClass: function(e) {
    var loader;

    if(typeof(e) === 'object'){
      var that = e;
      loader = that.data('loader') || '.loader-1';
    }
    else if(typeof(e) === 'string'){
      loader = e;
    } else{
      loader = '.loader-1';
    }

    loader = $(loader);

    return loader;
  }
};
